import Rebase from "re-base";
import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyA-2g4aVLLl3Pks_rVQ3bYk-EOo1yOHwXY",
    authDomain: "cotd-2024.firebaseapp.com",
    databaseURL: "https://cotd-2024-default-rtdb.firebaseio.com"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig);

const base = Rebase.createClass(firebaseApp.database());

export { firebaseApp }; // named export
export default base;    // default export